import * as React from 'react'
import GlanceInfo from "../components/glance-info";
import Nav from "../components/nav"
import Footer from "../components/footer"
import CtaBar from "../components/cta-bar";
import ReviewSection from "../components/review-section";
import Services from "../components/services";
import AboutBusiness from "../components/about-business";
import CtaBarAlternate from "../components/Cta-bar-alternate";
import { graphql } from "gatsby";
import {Helmet} from "react-helmet";
import ExperienceBar from "../components/experience-bar";
import Hero from "../components/hero";
import ImageGalleryShowcase from "../components/image-gallery-showcase";
import AboutBusinessTwo from "../components/about-business-two";

//npx gatsby develop

export const query = graphql`
  query {
    allDataJson {
    edges {
        node {
            About_Us
            Better_Explanation
            Business_Name
            Contact_Details {
                Address
                Email
                Hours
                Phone
                Phone_Without_Formatting
                Service_Areas
            }
            Expert_Explanation
            Main_Services {
                Description
                Title
            }
            Other_Services {
                Description
                Title
            }
            Pages {
                Image
                Title
            }
            Qualities
            Tag_Line
            Title
            Years_Experience
        }
    }
}
}

`

const IndexPage = ({ data }) => {
    const WebsiteContent = data.allDataJson.edges[0].node


    return (
        <>
            <Helmet>
                <title>{WebsiteContent.Business_Name + " | " + WebsiteContent.Title}</title>
                <meta name="description" content={"Tom brings with him 18 years of experience in designing, building, and finishing residential and commercial stairs. His passion is woodworking and he has some of the highest standards in the industry, fine details that most ignore are areas where Straight Up Stairs shine."} />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <main className={"font-roboto"}>
                {//<p>hello</p>
                //<p>{randomFromSeed("fdsfewgr234324!g h!!fwee-ffew")}</p>
                //<p>{randomFromSeedBool("fdsfeewtsgrfewegeffew").toString()}</p>
                }

                <Nav/>
                <Hero/>
                <GlanceInfo />
                <AboutBusiness />
                <CtaBar/>
                <Services/>
                <ExperienceBar/>
                <AboutBusinessTwo/>
                <ImageGalleryShowcase/>
                <ReviewSection/>
                <CtaBarAlternate/>
























            </main>
            <Footer />
        </>
    )
}

export default IndexPage