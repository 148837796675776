import * as React from 'react'
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";

// function SingleImage(props){
//     return(
//             <img className={"sm:w-1/6 w-1/2"} src={props.src} alt={props.alt}/>
//     )
// }

function ImageGalleryShowcase(){
    const data = useStaticQuery(graphql`
{
allFile(filter: {relativeDirectory: {eq: "images/gallery"}}) {
    edges {
      node {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
}



`)

    const ImageContent = data.allFile.edges

    return(
        <>
            <div className={"px-4 py-16 bg-main-color-500"}>
                <div className={"flex justify-evenly items-center flex-wrap max-w-5xl mx-auto"}>
                    <h1 className={"text-center text-6xl text-white font-bold sm:text-6xl max-w-lg"} >Our Work</h1>
                </div>
            </div>
        <div className={"flex flex-wrap dark:bg-gray-700 justify-center"}>
            {
                ImageContent.map((node, i) => (
                    <GatsbyImage key={i} className={"sm:w-1/6 w-1/2"} alt={"Example Work"} image={node.node.childImageSharp.gatsbyImageData}/>
                ))
            }
        </div>
        </>
    )
}

export default ImageGalleryShowcase